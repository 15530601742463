<header id="header" class="header-effect-shrink" data-plugin-options="{'stickyEnabled': true, 'stickyEffect': 'shrink', 'stickyEnableOnBoxed': true, 'stickyEnableOnMobile': true, 'stickyChangeLogo': true, 'stickyStartAt': 30, 'stickyHeaderContainerHeight': 70}">
  <div class="header-body">
    <div class="header-container container">
      <div class="header-row">
        <div class="header-column">
          <div class="header-row">
            <div class="header-logo">
              <a href="/">
                <img alt="Porto"  height="68" data-sticky-width="82" data-sticky-height="40" src="assets/img/logo.png">
              </a>
            </div>
          </div>
        </div>
        <div class="header-column justify-content-end">
          <div class="header-row">
            <div class="header-nav header-nav-line header-nav-top-line header-nav-top-line-with-border order-2 order-lg-1">
              <div class="header-nav-main header-nav-main-square header-nav-main-effect-2 header-nav-main-sub-effect-1">
                <nav class="collapse">
                  <ul class="nav nav-pills" id="mainNav">
                    <li>
                      <a href="/" routerLinkActive="active" [ngClass]="{'active' : path == '/' }" [routerLinkActiveOptions]="{ exact: true }">
                        Home
                      </a>
                    </li>
                    <li>
                      <a href="about" [ngClass]="{'active' : path == '/about' }" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                        About
                      </a>
                    </li>

                    <li>
                      <a href="sectors" [ngClass]="{'active' : path == '/sectors' }" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                        Sectors
                      </a>
                    </li>

                    <li class="dropdown">
                      <a class="dropdown-item dropdown-toggle" [ngClass]="{'active' : path == '/services' }"  href="services"  routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                        Services
                      </a>
                      <ul class="dropdown-menu">
                        <li><a class="dropdown-item" href="services">All Services</a></li>
                        <li *ngFor="let service of services"><a class="dropdown-item" [href]="'services/' + service.link">{{service.name}}</a></li>
                      </ul>
                    </li>
                    <li>
                      <a href="contact" [ngClass]="{'active' : path == '/contact' }" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                        Contact
                      </a>
                    </li>


                  </ul>
                </nav>
              </div>
              <button class="btn header-btn-collapse-nav" data-toggle="collapse" data-target=".header-nav-main nav">
                <i class="fas fa-bars"></i>
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</header>
