
<div class="body">

  <app-navbar></app-navbar>

  <router-outlet></router-outlet>

  <app-footer></app-footer>
</div>

