<div role="main" class="main">

  <section class="page-header page-header-modern bg-color-light-scale-1 page-header-md ">
    <div class="container-fluid">

    </div>
  </section>

  <div class="container pt-2 pb-4">

    <div class="row ">
      <div class="col-md-6 mb-4 mb-md-0 appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="300">

        <div class="owl-carousel owl-theme nav-inside nav-inside-edge nav-squared nav-with-transparency nav-dark mt-3" data-plugin-options="{'items': 1, 'margin': 10, 'loop': false, 'nav': true, 'dots': false}">
          <div>
            <div class="img-thumbnail border-0 border-radius-0 p-0 d-block">
              <img [src]="service.image" class="img-fluid border-radius-0" alt="">
            </div>
          </div>
        </div>

        <hr class="solid my-5 appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="1000">

      </div>
      <div class="col-md-6">
        <div class="overflow-hidden">
          <h2 class="text-color-dark font-weight-normal text-4 mb-0 appear-animation" data-appear-animation="maskUp" data-appear-animation-delay="600"><strong class="font-weight-extra-bold">{{service.name}}</strong></h2>
        </div>
        <p class="appear-animation" [innerHTML]="service.description" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="800">

        </p>
      </div>

    </div>

    <div class="col-12 row">
      <div class="overflow-hidden">
        <h2 class="text-color-dark font-weight-normal text-4 mb-0 appear-animation" data-appear-animation="maskUp" data-appear-animation-delay="1000"> <strong class="font-weight-extra-bold">

          {{service.list_title}}
        </strong></h2>
      </div>

      <div class="col-lg-12 row">

        <div *ngFor="let item of service.list" class="col-md-3">
          <span class="list list-icons list-primary list-borders text-2 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1200">
          <i class="fas fa-caret-right left-10" style="color:#f07d00;padding-right:5px"></i>{{item}}
          </span>

        </div>
      </div>


    </div>

  </div>



</div>
