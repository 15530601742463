<div role="main" class="main">
  <section
    class="page-header page-header-modern page-header-background page-header-background-md overlay overlay-color-dark overlay-show overlay-op-7"
    style="background-image: url(assets/img/about1.jpg);">
    <div class="container">
      <div class="row mt-5">
        <div class="col-md-12 align-self-center p-static order-2 text-center">
          <h1 class="text-9 font-weight-bold">About Us</h1>
          <span class="sub-title">The perfect choice for your next project</span>
        </div>
      </div>
    </div>
  </section>

  <div class="container pb-1">
    <div class="row pt-4">
      <div class="col">
        <div class="overflow-hidden mb-3">
          <h2 class="word-rotator slide font-weight-bold text-8 mb-0 appear-animation" data-appear-animation="maskUp">
            <span>We will take your business and operations to </span>
            <span class="word-rotator-words bg-primary">
              <b class="is-visible">greater heights</b>
              <b>greater profits</b>
              <b>greater efficiency</b>
            </span>
          </h2>
        </div>
      </div>
      <div class="col-lg-2 appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="450">
        <a href="assets/#" class="btn btn-modern btn-primary mt-1">Contact Us Now!</a>
      </div>
    </div>
  </div>
  <section class="section  border-0 my-5 appear-animation" data-appear-animation="fadeIn"
    data-appear-animation-delay="750">
    <div class="container py-4">

      <div class="row align-items-center">
        <div class="col-md-12">
          <div class="overflow-hidden mb-2">
            <h2 class="text-color-dark font-weight-normal text-5 mb-0 pt-0 mt-0 appear-animation"
              data-appear-animation="maskUp" data-appear-animation-delay="1200">Who <strong
                class="font-weight-extra-bold">We Are</strong></h2>
          </div>
          <p class="appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1400">

            EOAC is a highly reputed firm of Chartered Accountants and Consultants, providing Audit, Tax, ICT, Forensic
            and Advisory services. It came into being with a very strong team of multi-disciplinary professionals,
            ranging from Chartered Accountants, Auditors, Tax Practitioners, ICT Experts, Forensic Accountants/Auditors,
            Management Consultants, Economists, Human Resource Managers, Bankers, Administrators, Engineers and Legal
            practitioners
          </p>
          <p class="mb-0 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1600">
            The firm is licensed by the Institute of Chartered Accountants of Nigeria (ICAN), registered with the
            Corporate Affair Commission and accredited by the Auditor General of the Federal Republic of Nigeria,
            Federal Inland Revenue Service (FIRS), Financial Reporting Council of Nigeria and other relevant authorities
            with many international affiliations.
          </p>
          <p class="mb-0 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="1600">
            EOAC has unparalleled reputation in: Audit and Assurance Services, Tax, Information and Communications
            Technology, Forensic, Financial Advisory Services, Management Consulting, Entrepreneurship Development,
            Trust Administration and Services to Charity Organizations, Corporate Secretariat and Legal Services.
            <br>
            EOAC is structured to conveniently provide any of the above professional services anywhere in the world.
            Clients’ success is our goal as we see our role as Financial Advisers whenever objective or creative input
            is needed which combines professional expertise with commercial awareness.

          </p>
        </div>
      </div>

    </div>
  </section>

  <div class="container">

    <div class="row mt-5 py-3">
      <div class="col-md-6">
        <div class="toggle toggle-primary toggle-simple m-0" data-plugin-toggle>
          <section class="toggle active mt-0">
            <label>Our Vision</label>
            <div class="toggle-content">
              <p>
                To be a leading world assurance and consulting firm, providing quality Assurance, Tax, ICT, Forensic and
                Advisory Services across the globe
              </p>
            </div>
          </section>
          <section class="toggle">
            <label>Our Mission</label>
            <div class="toggle-content">
              <p>
                Our mission is to continue to provide unequal assurance and consulting services, leveraging on our
                expertise, resources and integrity, thereby enhancing our clients’ corporate image, growth and
                stability.
              </p>
            </div>
          </section>
        </div>
      </div>
      <div class="col-md-6 appear-animation" data-appear-animation="fadeInLeftShorter"
        data-appear-animation-delay="1000">
        <div class="owl-carousel owl-theme nav-inside mb-0"
          data-plugin-options="{'items': 1, 'margin': 10, 'animateOut': 'fadeOut', 'autoplay': true, 'autoplayTimeout': 6000, 'loop': true}">
          <div>
            <img alt="" class="img-fluid" src="assets/img/about1.jpg">
          </div>
          <div>
            <img alt="" class="img-fluid" src="assets/img/about2.jpg">
          </div>
        </div>
      </div>

    </div>

  </div>

  <section class="section section-default border-0 my-0">
    <div class="container py-4">

      <div class="row">
        <div class="col pb-4 text-center">
          <h2 class="text-color-dark font-weight-normal text-5 mb-0 pt-2">Our <strong
              class="font-weight-extra-bold">Leadership</strong></h2>
          <p>
            The peculiarity of EOAC is the high profile of our partners and staff broad-based knowledge which cut across
            public and private sectors of both local and international experiences. Our team has undergone a series of
            unrivalled training, developmental and research work at home and abroad. These and many more stand out our
            firm as a peculiar brand indeed.
          </p>
        </div>
      </div>
      <div class="row pb-4 mb-2">
        <div class="col-sm-12 offset-lg-3 col-lg-6 mb-4 mb-lg-0 appear-animation"
          data-appear-animation="fadeInRightShorter">
          <span class="thumb-info thumb-info-hide-wrapper-bg bg-transparent border-radius-0">

            <span class="thumb-info-caption">
              <span class="thumb-info-title">
                <span class="thumb-info-inner">ERNEST ABEGBE</span>
                <span class="thumb-info-type"> B.Sc, PGDE, PGD, M.Sc MBA, FCA, FCTI, FICM, CFAN</span>
              </span>

              <span class="thumb-info-caption-text">

                Ernest is a managing partner of Ernest O. Abegbe and Company. He has a Bachelor Degree (B.Sc. Hons) in
                Accounting, Post Graduate Diploma (PGD) in Economics and Education, M.Sc in Economics and MBA in
                Business Administration. He is a Certified Forensic Accountant of Nigeria and fellow of the Institute of
                Chartered Accountants of Nigeria (ICAN), Chartered Institute of Taxation of Nigeria (CITN) and Institute
                of Management Consultants. He is a Ph.D. Student in Economics and seasoned
                professional with wide range of experience in both the public and private sectors; Audit, Tax, ICT,
                Forensic, Economy and Advisory Services. He is also the Managing Consultant of EGA Consulting Ltd, Eagle
                Forensic & IT Consult and
                President of the Organisation for Global Restoration.

              </span>

              <span>
                He has managed several projects in the areas of Treasury
                Management, Economy, Value for Money Audit, Monitoring and Evaluation, Taxation,
                Financial Advisory Services, Asset Management and Recovery, Corporate Reorganization, Entrepreneurship
                Development and Capacity Building. a member of the Technical Working Group (TWG) for preparation of
                Medium Term National Development Plan (MTNDP) and
                Nigeria Agenda
              </span>
            </span>
          </span>
        </div>
      </div>
    </div>
  </section>
  <section class="section border-0 my-0 white-background">
    <div class="container py-4">
      <div class="row pb-4 mb-2">

        <div class="col-sm-12 col-lg-6 mb-4 mb-lg-0 appear-animation" data-appear-animation="fadeInRightShorter"
          data-appear-animation-delay="200">
          <span class="thumb-info thumb-info-hide-wrapper-bg bg-transparent border-radius-0">
            <span class="thumb-info-caption">
              <span class="thumb-info-title">
                <span class="thumb-info-inner">MFON A. BROOKS</span>
                <span class="thumb-info-type"> BSc. PGD, MBA, ACTI, FCA</span>

              </span>
            </span>

            <span class="thumb-info-caption">
              <span class="thumb-info-caption-text">
                Brooks had his Bachelor Degree (B.Sc. Hons) in Accounting, Post Graduate Diploma (PGD) in Management and
                MBA in Business Administration. He is a fellow of the Institute of Chartered Accountants of Nigeria
                (ICAN) and an associate member of Chartered Institute of Taxation of Nigeria (CITN). He is well equipped
                in Audit, Tax, ICT, Forensic and Management Consulting.
              </span>
            </span>
          </span>
        </div>
        <div class="col-sm-12 col-lg-6 mb-4 mb-sm-0 appear-animation" data-appear-animation="fadeInRightShorter"
          data-appear-animation-delay="400">
          <span class="thumb-info thumb-info-hide-wrapper-bg bg-transparent border-radius-0">

            <span class="thumb-info-caption">
              <span class="thumb-info-title">
                <span class="thumb-info-inner">ELIZABETH CHIZEA-ESOSUOTA</span>
                <span class="thumb-info-type">OND, MBA, ACTI, FCA</span>

              </span>
            </span>

            <span class="thumb-info-caption">
              <span class="thumb-info-caption-text">

                Elizabeth has a Bachelor Degree (B.Sc. Hons) in Accounting, Post Graduate Diploma (PGD) in Management
                and MBA in Business Administration. She is a fellow of the Institute of Chartered Accountants of Nigeria
                (ICAN) and an associate member of Chartered Institute of Taxation of Nigeria (CITN). She is well
                equipped in Audit, Tax, ICT, Forensic and Advisory Services and she also worked with the Federal
                Ministry of Mines and Power and Federal Ministry of Petroleum as a clerical officer and accountant.
              </span>
            </span>
          </span>
        </div>
      </div>

      <h2 class="text-color-dark font-weight-normal text-5 mb-0 pt-2 prof">OTHER PROFESSIONAL STAFF</h2>

      <p>
        In addition to the partners, the firm’s personnel include chartered accountants, consultants, project managers
        and evaluators, tax practitioners, ICT experts, forensic accountants/auditors, management consultants,
        economists, bankers, human resource managers, bankers, administrators, engineers and legal practitioners and
        administrative support staff. these put our firm at a vantage position of leadership in professional services
        delivery.
      </p>
    </div>
  </section>

  <div class="container">
    <div class="row py-5 my-5">
      <div class="col">

        <h2 class="text-color-dark font-weight-normal text-5 mb-0 pt-2">Our <strong
            class="font-weight-extra-bold">Clients</strong></h2>

        <p>
          Our clientele cuts across various sectors of the economy.<br>
          We provide services not only in Nigeria but also anywhere in the world to government, individuals and
          organisations of both public and private sectors.<br>
          <b>SOME OF OUR CLIENTS ARE:</b> <br>
          1. Office of the Auditor General for the Federation (OAuGF)<br>
          2. Federal Inland Revenue Service (FIRS)<br>
          3. Bank of Industry (BOI)<br>
          4. FCTA<br>
          5 Corporate Affairs Commission (CAC)<br>
          6. Federal Ministry of Finance (FMF)<br>
          7. Federal Ministry of Budget and National Planning<br>
          8. World Bank Group<br>
          9. Society for Family Health (FHI 360)<br>
          10. German Agency for International Cooperation (GIZ)<br>
          11. Revenue Mobilisation Allocation and Fiscal Commission (RMAFC)<br>
          12. National Pension Commission (PENCOM)<br>
          13. Others are multi-national companies, organisations and individuals<br>
          14. NATIONAL ASSEMBLY (NASS)<br>
          15. NIGERIA EXTRACTIVE INDUSTRIES TRANSPARENCY INITIATIVE (NEITI)<br>
          16. SURVEYORS COUNCIL OF NIGERIA' (SURCON)<br>
          17. NATIONAL INFORMATION DEVELOPMENT AGENCY (NITDA)<br>
          18. FEDERAL CAPITAL TERRITORY INTERNAL REVENUE SERVICE (FCTIRS)<br>
          19. ABUJA ENVIRONMENAL PROTECTION BOARD (AEPB)<br>
          20. FEDERAL CAPITAL TERRITORY ADMINISTRATION (FCTA) .
        </p>
        <div class="owl-carousel owl-theme mb-0"
          data-plugin-options="{'responsive': {'0': {'items': 1}, '476': {'items': 1}, '768': {'items': 5}, '992': {'items': 7}, '1200': {'items': 7}}, 'autoplay': true, 'autoplayTimeout': 3000, 'dots': false}">
          <div *ngFor="let client of dataService.clients">
            <img class="img-fluid opacity-2" [src]="client" alt="">
          </div>
        </div>

      </div>
    </div>
  </div>

</div>
