<div role="main" class="main">

  <!-- Google Maps - Go to the bottom of the page to change settings and map location. -->
  <div id="googlemaps" class="google-map mt-0" style="background:url(assets/img/map.png); background-size:cover;height: 500px;">
  </div>

  <div class="container">

    <div class="row py-4">
      <div class="col-lg-12">

        <div class="overflow-hidden mb-1">
          <h2 class="font-weight-normal text-7 mt-2 mb-0 appear-animation" data-appear-animation="maskUp" data-appear-animation-delay="200"><strong class="font-weight-extra-bold">Contact</strong> Us</h2>
        </div>
        <div class="overflow-hidden mb-4 pb-3">
          <p class="mb-0 appear-animation" data-appear-animation="maskUp" data-appear-animation-delay="400">We will like to hear from you!</p>
        </div>

      </div>
      <div class="col-lg-12 row">

        <div class="col-lg-4">
          <div class="appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="800">
            <h4 class="mt-2 mb-1">Head <strong>Office</strong></h4>
            <ul class="list list-icons list-icons-style-2 mt-2">
              <li><i class="fas fa-map-marker-alt top-6"></i> <strong class="text-dark">Address:</strong>
                Wing A, 2nd Floor, Oyo State House <br>
                By Federal Ministry of Finance <br>
                Central Business District <br>
                Abuja, Nigeria.
              </li>
              <li><i class="fas fa-phone top-6"></i> <strong class="text-dark">Phone:</strong>
                +234-8036136410,+234-92916940, +234-7040403541
              </li>
              <li><i class="fas fa-envelope top-6"></i> <strong class="text-dark">Email: </strong>
                <a href="mailto:eoabegbe@gmail.com">eoabegbe@gmail.com</a> <br>
                <a href="mailto:info@eoabegbe.com">info@eoabegbe.com</a> <br>
                <a href="mailto:eoacharteredaccountants@yahoo.com">eoacharteredaccountants@yahoo.com</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="800">
            <h4 class="mt-2 mb-1">Lagos <strong>Office</strong></h4>
            <ul class="list list-icons list-icons-style-2 mt-2">
              <li><i class="fas fa-map-marker-alt top-6"></i> <strong class="text-dark">Address:</strong>
                8, Yinusa Adeniji Street
                Off Toyin Street,
                Ikeja, Lagos, Nigeria
              </li>
              <li><i class="fas fa-phone top-6"></i> <strong class="text-dark">Phone:</strong> 08033205356, 08039357943</li>
            </ul>
          </div>
        </div>


        <div class="col-lg-4">
          <div class="appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="800">
            <h4 class="mt-2 mb-1">Benin <strong>Office</strong></h4>
            <ul class="list list-icons list-icons-style-2 mt-2">
              <li><i class="fas fa-map-marker-alt top-6"></i> <strong class="text-dark">Address:</strong>
                KM 8, Benin/Agbor Express Way
                Ikhueniro, Benin City.
                Edo State, Nigeria
              </li>
              <li><i class="fas fa-phone top-6"></i> <strong class="text-dark">Phone:</strong> 08032669149 </li>
            </ul>
          </div>
        </div>

        <div class="col-lg-4 col-sm-12">
          <div class="appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="800">
            <h4 class="mt-2 mb-1">Enugu <strong>Office</strong></h4>
            <ul class="list list-icons list-icons-style-2 mt-2">
              <li><i class="fas fa-map-marker-alt top-6"></i> <strong class="text-dark">Address:</strong>
                4, Oraukwu Street, Abakpa-Nike
                Enugu
                Enugu State, Nigeria
              </li>
              <li><i class="fas fa-phone top-6"></i> <strong class="text-dark">Phone:</strong> 08036407340 </li>
            </ul>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="800">
            <h4 class="mt-2 mb-1">Germany <strong>Office</strong></h4>
            <ul class="list list-icons list-icons-style-2 mt-2">
              <li><i class="fas fa-map-marker-alt top-6"></i> <strong class="text-dark">Address:</strong>
                Steinrohre gew,70499, Hausen ring
                Stuttgart, Germany.

              </li>
              <li><i class="fas fa-phone top-6"></i> <strong class="text-dark">Phone:</strong> 004915219212237 </li>
            </ul>
          </div>
        </div>


        <div class="col-lg-4">
          <div class="appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="950">
            <h4 class="mt-2 mb-1">Business <strong>Hours</strong></h4>

            <ul class="list list-icons list-dark mt-2">
              <li><i class="far fa-clock top-6"></i> Monday - Friday - 8am to 6pm</li>
              <li><i class="far fa-clock top-6"></i> Saturday - Open sometimes to meet work demand</li>
              <li><i class="far fa-clock top-6"></i> Sunday - Closed</li>
            </ul>
          </div>

        </div>


      </div>

    </div>

  </div>

</div>
