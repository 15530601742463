<footer id="footer" class="border-0 overlay">
  <div class="container py-4">
    <div class="row justify-content-md-center py-5 mt-3">
      <div class="col-md-12 col-lg-3 d-flex align-items-center justify-content-center justify-content-lg-start mb-5 mb-lg-0">
        <a href="/"><img src="assets/img/EOAC%20LOGO.png" alt="Logo" class="img-fluid" style="max-width: 110px;"></a>
      </div>
      <div class="col-md-6 col-lg-3 text-center text-lg-left mb-5 mb-lg-0">
        <h5 class="text-5 text-transform-none font-weight-semibold text-color-light mb-4">Pages</h5>
        <ul class="list list-unstyled">
          <li class="mb-1"><a href="about" class="link-hover-style-1"> About Us</a></li>
          <li class="mb-1"><a href="services" class="link-hover-style-1"> Our Services</a></li>
          <li class="mb-1"><a href="contact" class="link-hover-style-1"> Contact Us</a></li>
        </ul>
      </div>
      <div class="col-md-6 col-lg-3 text-center text-lg-left mb-5 mb-lg-0">
        <h5 class="text-5 text-transform-none font-weight-semibold text-color-light mb-4">SERVICES</h5>
        <ul class="list list-unstyled">
          <li class="mb-1" *ngFor="let service of services; let i = index"><a class="link-hover-style-1" [href]="'services/' + service.link">{{service.name}}</a></li>
        </ul>
      </div>

<!--      <div class="col-md-6 col-lg-3 text-center text-lg-left mb-5 mb-lg-0">-->
<!--        <h5 class="text-5 text-transform-none font-weight-semibold text-color-light mb-4">SERVICES</h5>-->
<!--        <ul class="list list-unstyled">-->
<!--          <li class="mb-1" *ngFor="let service of services; let i = index"><a *ngIf="i % 2 > 0" class="link-hover-style-1" [href]="'services/' + service.link">{{service.name}}</a></li>-->
<!--        </ul>-->
<!--      </div>-->

    </div>
  </div>
  <div class="footer-copyright footer-copyright-style-2 background-transparent footer-top-light-border">
    <div class="container py-2">
      <div class="row py-4">
        <div class="col d-flex align-items-center justify-content-center mb-4 mb-lg-0">
          <p>© Copyright 2020. All Rights Reserved.</p>
        </div>
      </div>
    </div>
  </div>
</footer>
