<div role="main" class="main">

    <div class="slider-container rev_slider_wrapper" style="height: 670px;">
      <div id="revolutionSlider" class="slider rev_slider" data-version="5.4.8" data-plugin-revolution-slider data-plugin-options="{'delay': 9000, 'gridwidth': 1170, 'gridheight': 670, 'disableProgressBar': 'on', 'responsiveLevels': [4096,1200,992,500], 'parallax': { 'type': 'scroll', 'origo': 'enterpoint', 'speed': 1000, 'levels': [2,3,4,5,6,7,8,9,12,50], 'disable_onmobile': 'on' }, 'navigation' : {'arrows': { 'enable': false }, 'bullets': {'enable': true, 'style': 'bullets-style-1', 'h_align': 'center', 'v_align': 'bottom', 'space': 7, 'v_offset': 70, 'h_offset': 0}}}">
        <ul>
          <li class="slide-overlay " data-transition="fade">
            <img src="assets/img/banners/banner1.jpg"
                 alt=""
                 data-bgposition="center center"
                 data-bgfit="cover"
                 data-bgrepeat="no-repeat"
                 class="rev-slidebg">

            <div class="tp-caption"
                 data-x="center" data-hoffset="['-145','-145','-145','-320']"
                 data-y="center" data-voffset="['-80','-80','-80','-130']"
                 data-start="1000"
                 data-transform_in="x:[-300%];opacity:0;s:500;"
                 data-transform_idle="opacity:0.2;s:500;"><img src="assets/img/slides/slide-title-border.png" alt=""></div>

<!--            <div class="tp-caption text-color-light font-weight-normal"-->
<!--                 data-x="center"-->
<!--                 data-y="center" data-voffset="['-80','-80','-80','-130']"-->
<!--                 data-start="700"-->
<!--                 data-fontsize="['16','16','16','40']"-->
<!--                 data-lineheight="['25','25','25','45']"-->
<!--                 data-transform_in="y:[-50%];opacity:0;s:500;">WE ARE A GLOBAL FIRM</div>-->

            <div class="tp-caption"
                 data-x="center" data-hoffset="['145','145','145','320']"
                 data-y="center" data-voffset="['-80','-80','-80','-130']"
                 data-start="1000"
                 data-transform_in="x:[300%];opacity:0;s:500;"
                 data-transform_idle="opacity:0.2;s:500;"><img src="assets/img/banners/slide-title-border.png" alt=""></div>

            <div class="tp-caption font-weight-extra-bold text-color-light"
                 data-frames='[{"delay":1300,"speed":1000,"frame":"0","from":"opacity:0;x:-50%;","to":"opacity:0.7;x:0;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                 data-x="center" data-hoffset="['-175','-175','-175','-275']"
                 data-y="center"
                 data-fontsize="['145','145','145','250']"
                 data-lineheight="['150','150','150','260']">E</div>

            <div class="tp-caption font-weight-extra-bold text-color-light"
                 data-frames='[{"delay":1500,"speed":1000,"frame":"0","from":"opacity:0;x:-50%;","to":"opacity:0.7;x:0;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                 data-x="center" data-hoffset="['-80','-80','-80','-130']"
                 data-y="center"
                 data-fontsize="['145','145','145','250']"
                 data-lineheight="['150','150','150','260']">O</div>


            <!--                        <div class="tp-caption font-weight-extra-bold text-color-light"-->
            <!--                             data-frames='[{"delay":1700,"speed":1000,"frame":"0","from":"opacity:0;x:-50%;","to":"opacity:0.7;x:0;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'-->
            <!--                             data-x="center"  data-hoffset="['65','65','65','115']"-->
            <!--                             data-y="center"-->
            <!--                             data-fontsize="['145','145','145','250']"-->
            <!--                             data-lineheight="['150','150','150','260']">A</div>-->

            <div class="tp-caption font-weight-extra-bold text-color-light"
                 data-frames='[{"delay":1900,"speed":1000,"frame":"0","from":"opacity:0;x:-50%;","to":"opacity:0.7;x:0;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                 data-x="center" data-hoffset="['25','115','115','135']"
                 data-y="center"
                 data-fontsize="['145','145','145','250']"
                 data-lineheight="['150','150','150','260']">A</div>

            <div class="tp-caption font-weight-extra-bold text-color-light"
                 data-frames='[{"delay":2100,"speed":1000,"frame":"0","from":"opacity:0;x:-50%;","to":"opacity:0.7;x:0;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                 data-x="center" data-hoffset="['139','139','139','240']"
                 data-y="center"
                 data-fontsize="['145','145','145','250']"
                 data-lineheight="['150','150','150','260']">C</div>

            <div class="tp-caption font-24 font-weight-light text-color-light"
                 data-frames='[{"from":"opacity:0;","speed":300,"to":"o:1;","delay":2300,"split":"chars","splitdelay":0.05,"ease":"Power2.easeInOut"},{"delay":"wait","speed":1000,"to":"y:[100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'
                 data-x="center"
                 data-y="center" data-voffset="['85','85','85','140']"
                 data-fontsize="['18','18','18','40']"
                 data-lineheight="['26','26','26','45']"></div>

          </li>
          <li class="slide-overlay" data-transition="fade">
            <img src="assets/img/banners/banner2.jpg"
                 alt=""
                 data-bgposition="center center"
                 data-bgfit="cover"
                 data-bgrepeat="no-repeat"
                 class="rev-slidebg">

            <h1 class="tp-caption font-16 font-weight-extra-bold text-color-light negative-ls-2"
                data-frames='[{"delay":1000,"speed":2000,"frame":"0","from":"sX:1.5;opacity:0;fb:20px;","to":"o:1;fb:0;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                data-x="center"
                data-y="center" data-voffset="['-55','-55','-55','-55']"
                data-fontsize="['50','50','50','90']"
                data-lineheight="['55','55','55','95']"
                data-letterspacing="-1">WE ARE TRUSTED MULTI-DISCIPLINARY PROFESSIONALS  </h1>

            <div class="tp-caption font-weight-light text-color-light"
                 data-frames='[{"from":"opacity:0;","speed":300,"to":"o:0.8;","delay":2000,"split":"chars","splitdelay":0.05,"ease":"Power2.easeInOut"},{"delay":"wait","speed":1000,"to":"y:[100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'
                 data-x="center"
                 data-y="center" data-voffset="['-5','-5','-5','15']"
                 data-fontsize="['18','18','18','35']"
                 data-lineheight="['20','20','20','40']">
              ........providing Audit & Assurance, Tax, ICT, Forensic, Revenue Automation  and Advisory Services across the globe.
           </div>

            <a class="tp-caption btn btn-light font-weight-bold text-color-primary"
               href="#"
               data-frames='[{"delay":3000,"speed":2000,"frame":"0","from":"y:50%;opacity:0;","to":"y:0;o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
               data-x="center" data-hoffset="['-120','-120','-120','-195']"
               data-y="center" data-voffset="['65','65','65','105']"
               data-paddingtop="['15','15','15','30']"
               data-paddingbottom="['15','15','15','30']"
               data-paddingleft="['33','33','33','50']"
               data-paddingright="['33','33','33','50']"
               data-fontsize="['13','13','13','25']"
               data-lineheight="['20','20','20','25']">ABOUT US</a>

            <a class="tp-caption btn btn-primary font-weight-bold"
               href="#"
               data-frames='[{"delay":3000,"speed":2000,"frame":"0","from":"y:50%;opacity:0;","to":"y:0;o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
               data-x="center" data-hoffset="['90','90','90','165']"
               data-y="center" data-voffset="['65','65','65','105']"
               data-paddingtop="['15','15','15','30']"
               data-paddingbottom="['15','15','15','30']"
               data-paddingleft="['33','33','33','50']"
               data-paddingright="['33','33','33','50']"
               data-fontsize="['13','13','13','25']"
               data-lineheight="['20','20','20','25']">CONTACT US<i class="fas fa-arrow-right ml-1"></i></a>

          </li>
        </ul>
      </div>
    </div>

    <div class="home-intro mb-0" id="home-intro">
      <div class="container">

        <div class="row align-items-center">
          <div class="col-lg-8">
            <p>
              Guaranteed quality, timely and cost effective <span class="highlighted-word highlighted-word-animation-1 text-color-primary font-weight-semibold text-5">delivery</span>
              <span>We offer a vast array of quality professional services </span>
            </p>
          </div>
          <div class="col-lg-4">
            <div class="get-started text-left text-lg-right">
              <a href="#" class="btn btn-primary btn-lg text-3 font-weight-semibold px-4 py-3">Contact Us Now!</a>
              <div class="learn-more">or <a href="services.html">view our services.</a></div>
            </div>
          </div>
        </div>

      </div>
    </div>


    <section class="section bg-color-grey-scale-1 section-height-3 border-0 m-0">
      <div class="container">
        <div class="row">
          <div class="col">
            <h2 class="font-weight-normal text-center text-6 pb-3">Our <strong class="font-weight-extra-bold">Services</strong></h2>
          </div>
        </div>


        <div class="row mb-lg-4">
          <div class="col-lg-4 appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="300">
            <div class="feature-box feature-box-style-2">
              <div class="feature-box-icon">
                <i class="icons icon-support text-color-primary"></i>
              </div>
              <div class="feature-box-info">
                <a href='/services/audit-and-assurance' class="pointer no-decoration">
                  <h4 class="font-weight-bold mb-2">Audit and Assurance Services</h4>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 appear-animation" data-appear-animation="fadeInUpShorter">
            <div class="feature-box feature-box-style-2">
              <div class="feature-box-icon">
                <i class="icons icon-layers text-color-primary"></i>
              </div>
              <div class="feature-box-info">
                <a href='/services/tax' class="pointer no-decoration">
                  <h4 class="font-weight-bold mb-2">Tax</h4>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="300">
            <div class="feature-box feature-box-style-2">
              <div class="feature-box-icon">
                <i class="icons icon-menu text-color-primary"></i>
              </div>
              <div class="feature-box-info">
                <a href='/services/forensics' class="pointer no-decoration">
                  <h4 class="font-weight-bold mb-2" >Forensic Services </h4>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="300">
            <div class="feature-box feature-box-style-2">
              <div class="feature-box-icon">
                <i class="icons icon-doc text-color-primary"></i>
              </div>
              <div class="feature-box-info">
                <a href='/services/revenue-generation-and-automation' class="pointer no-decoration">
                  <h4 class="font-weight-bold mb-2">Revenue Generation and Automation</h4>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 appear-animation" data-appear-animation="fadeInUpShorter">
            <div class="feature-box feature-box-style-2">
              <div class="feature-box-icon">
                <i class="icons icon-user text-color-primary"></i>
              </div>
              <div class="feature-box-info">
                <a href='/services/management-consulting' class="pointer no-decoration">
                  <h4 class="font-weight-bold mb-2">Management Consulting</h4>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="300">
            <div class="feature-box feature-box-style-2">
              <div class="feature-box-icon">
                <i class="icons icon-screen-desktop text-color-primary"></i>
              </div>
              <div class="feature-box-info">
                <a href='/services/accounting' class="pointer no-decoration">
                  <h4 class="font-weight-bold mb-2">Accounting and Financial Advisory Services</h4>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="300">
            <div class="feature-box feature-box-style-2">
              <div class="feature-box-icon">
                <i class="icons icon-doc text-color-primary"></i>
              </div>
              <div class="feature-box-info">
                <a href='/services/ict-and-communications' class="pointer no-decoration">
                <h4 class="font-weight-bold mb-2">Information and Communications Technology (ICT)</h4>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 appear-animation" data-appear-animation="fadeInUpShorter">
            <div class="feature-box feature-box-style-2">
              <div class="feature-box-icon">
                <i class="icons icon-user text-color-primary"></i>
              </div>
              <div class="feature-box-info">
                <a href='/services/entrepreneurship-development' class="pointer no-decoration">
                  <h4 class="font-weight-bold mb-2">Entrepreneurship Development</h4>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="300">
            <div class="feature-box feature-box-style-2">
              <div class="feature-box-icon">
                <i class="icons icon-screen-desktop text-color-primary"></i>
              </div>
              <div class="feature-box-info">
                <a href='/services/trust-administration' class="pointer no-decoration">
                 <h4 class="font-weight-bold mb-2">Trust Administration and Services to Charity Organizations</h4>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>



    <div class="container-fluid">
      <div class="row featured-boxes-full">
        <div class="col-lg-2" style="background-image: url(img/gallery/gallery-2.jpg); background-position: center; background-repeat: no-repeat; background-size: cover;"></div>
        <div class="col-lg-4 featured-box-full featured-box-full-light text-left">
          <i class="fas fa-film"></i>
          <h4 class="font-weight-normal text-5">Our <strong class="font-weight-extra-bold">Vision</strong></h4>
          <p class="mb-0">
            To become a leading world assurance and consulting firm, providing quality Assurance, Tax, ICT, Forensic and Advisory Services across the globe.
          </p>
        </div>
        <div class="col-lg-4 featured-box-full featured-box-full-dark text-left">
          <i class="far fa-edit"></i>
          <h4 class="font-weight-normal text-5">Our <strong class="font-weight-extra-bold">Mission</strong></h4>
          <p class="mb-0">
            Our mission is to continue to provide unequal professional services, leveraging on our unrivalled expertise in Assurance, Tax, ICT, Forensic and Advisory Services, thereby, enhancing our clients’ corporate image, growth and stability. .</p>
        </div>
        <div class="col-lg-2" style="background-image: url(img/gallery/gallery-3.jpg); background-position: center; background-repeat: no-repeat; background-size: cover;"></div>

      </div>
    </div>

    <div class="container pt-2">
      <div class="row my-5 pb-5">
        <div class="col-lg-12 pr-5">
          <h2 class="font-weight-normal text-6 mb-2 pb-1"><strong class="font-weight-extra-bold">Who</strong> We Are</h2>
          <p class="lead">
            EOAC is a global firm, solidly founded with team of trusted multi-disciplinary professionals, providing Assurance, Tax, Information and Communications Technology (ICT), Forensic and Advisory Services to a wide range of clients across the globe. We are dedicated to developing our clients into informed decision makers and designing unique solutions that help deliver maximum growth and wealth potentials.
          </p>
          <p>
            All of the above and many more make EOAC one of the fastest growing firms today.
          </p>
          <a href="#" class="font-weight-semibold text-decoration-none learn-more text-2">MORE ABOUT US <i class="fas fa-chevron-right ml-2"></i></a>
        </div>
      </div>

    </div>

    <section class="section section-height-3 section-background section-text-light section-center overlay overlay-show overlay-op-9 overlay-color-primary m-0 appear-animation" data-appear-animation="fadeIn" style="background-image: url(img/bg-corporate-1-1.jpg); background-size: cover; background-position: center;">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="200">
            <div class="owl-carousel owl-theme nav-bottom rounded-nav dots-light mb-0" data-plugin-options="{'items': 1, 'loop': false, 'autoHeight': true}">
              <div>
                <div class="col">
                  <div class="testimonial testimonial-style-2 testimonial-with-quotes mb-0">
                    <div class="testimonial-author">
                      <img src="assets/img/clients/client-1.jpg" class="img-fluid rounded-circle" alt="">
                    </div>
                    <blockquote>
                      <p>

                        Our clientele cuts across various sectors of the economy.
                        We provide services not only in Nigeria but also anywhere in the world to government, individuals and organisations of both public and private sectors.
                        Our commitment to you is to deliver the best services.

                      </p>
                    </blockquote>
                    <div class="testimonial-author">
                      <p><strong class="text-2 opacity-10">- Ernest O. Abegbe (Managing Partner) </strong></p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="col">
                  <div class="testimonial testimonial-style-2 testimonial-with-quotes mb-0">
                    <div class="testimonial-author">
                      <img src="assets/img/clients/client-1.jpg" class="img-fluid rounded-circle" alt="">
                    </div>
                    <blockquote>
                      <p>
                        Our numerous years of experience enable us to offer faster, more efficient and meticulous services. I enjoin you to contact us
                        and find out how we can be of help to your organization.
                      </p>
                    </blockquote>
                    <div class="testimonial-author">
                      <p><strong class="text-2 opacity-10">- MFON A. BROOKS</strong></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="container">
      <div class="row text-center pt-4 mt-5">
        <div class="col">
          <h2 class="word-rotator slide font-weight-bold text-8 mb-2">
            <span>EOAC is </span>
            <span class="word-rotator-words bg-dark">
										<b class="is-visible">incredibly</b>
										<b>extremely</b>
									</span>
            <span> capable of handling your projects.</span>
          </h2>
          <h4 class="text-primary lead tall text-4">YOU ARE IN GOOD COMPANY.</h4>
        </div>
      </div>

      <div class="row text-center mt-5 pb-5 mb-5">
        <div class="owl-carousel owl-theme carousel-center-active-item mb-0" data-plugin-options="{'responsive': {'0': {'items': 1}, '476': {'items': 1}, '768': {'items': 5}, '992': {'items': 7}, '1200': {'items': 7}}, 'autoplay': true, 'autoplayTimeout': 3000, 'dots': false}">
          <div *ngFor="let client of dataService.clients" >
            <img class="img-fluid opacity-2" [src]="client" alt="">
          </div>
        </div>
      </div>
    </div>

  </div>
